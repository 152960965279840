/* NotificationCard.css */

.notification-card {
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  width: 100%;
  margin: 4px;
  position: relative;
  transition: background-color 0.3s ease;
}

.notification-card.unread::after {
  content: "";
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  top: 16px;
  right: 16px;
}

.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.notification-header h3 {
  margin: 0;
  font-size: 18px;
}

.close-btn {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.close-btn:hover {
  color: #ff7f00; /* Darker orange on hover */
}

.notification-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 8px;
}

.notification-date {
  font-size: 12px;
  margin-top: 8px;
  display: block;
  text-align: right;
}

p {
  margin: 0;
  font-size: 14px;
}
