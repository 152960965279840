/* ReviewCard.css */
.review-card {
    background-color: #fff;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px 0;
  }
  
  .review-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  
  .reviewer-image {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  
  .review-header h3 {
    margin: 0;
    font-size: 18px;
  }
  
  .review-score {
    background-color: #FFA412; /* Orange */
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
  }
  
  .review-text {
    font-size: 14px;
    margin: 10px 0;
  }
  
  .review-date {
    font-size: 12px;
    color: #999;
    text-align: right;
    display: block;
  }
  