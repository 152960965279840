.app-container {
  max-width: 540px;
  margin: 0 auto;
}
.App {
  font-family: sans-serif;
  text-align: center;
}

.slot {
  position: relative;
  display: inline-block;
  height: 80px;
  width: 60px;
}

@media (max-width: 767px) {
  .your-class {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .layout-screen {
    /*  */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .make-it-half {
    width: 40%;
  }
}

section {
  position: absolute;
  border-radius: 15px !important;
  /* border: 3px solid black !important; */
  width: 60px;
  height: 60px;
  overflow: hidden;
  /* background-color: grey; */
  border-radius: 2px;
  /* border: 1px solid lightgrey; */
  color: white;
  font-family: sans-serif;
  text-align: center;
  font-size: 25px;
  line-height: 60px;
  cursor: default;
}

.container {
  position: absolute;
  top: 2px;
  width: 100%;
  transition: top ease-in-out 0.5s;
  text-align: center;
}

.roll {
  width: 215px;
  cursor: pointer;
  background-color: yellow;
  padding: 10px;
  text-align: center;
  font-size: 20px;
  border-radius: 20px;
  border: 3px solid black;
}

.rolling {
  animation: blinkingText 1.2s infinite;
}

@keyframes blinkingText {
  0% {
    color: #000;
  }
  49% {
    color: #000;
  }
  60% {
    color: transparent;
  }
  99% {
    color: transparent;
  }
  100% {
    color: #000;
  }
}
.term-container {
  height: calc(100vh - 300px);
  overflow-y: scroll;
  font-size: 14px;
  font-family: Sarabun;
  color: #000000;
}

.ionic-alert {
  text-align: center;
}

.alert-head .sc-ion-alert-md {
  text-align: center;
}

.block-center {
  justify-content: center !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  display: flex !important;
}

.pin-pwd {
  padding: 0px !important;
  display: flex;
  justify-content: space-between;
  margin: 0px;
  background: none;
  max-width: 200px;
  width: 50%;
}

.pin-pwd > .pin-full {
  background-color: #0c0c0c;
  border: 2px solid #0c0c0c;
}

.pin-pwd > * {
  height: 1em;
  width: 1em;
  border-radius: 50%;
  display: inline-block;
}

.pin-pwd > .pin-hollow {
  background-color: white;
  border: 2px solid #0c0c0c;
}

.ion-img {
  margin: 15px;
  padding-left: 2px;
  padding-right: 2px;
}

.alertPromptAdd {
  color: green;
}

.alertPromptCancel {
  color: red;
}

.kgo-btn {
  --background: var(--kgo-color);
  border-radius: 8px;
}

.kgo-btn2 {
  --background: var(--kgo-color2);
  border-radius: 8px;
}

.kgo-btn-radius {
  --background: var(--kgo-color);
  --border-radius: 50%;
}

.kgo-btn.button-disabled {
  --background: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
}

.kgo-btn-dark {
  --background: rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.kgo-btn-light {
  --background: #f8f8f8;
  margin: 0;
  color: #242424;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.11);
}

.kgo-btn-scan {
  z-index: 1;
  right: 85%;
  top: -40%;
  bottom: 0;

  position: absolute;
  --background: #ffffff;
  margin: 0;
  color: #242424;
  border: 4px solid #797979;
  box-sizing: border-box;
  /* Drop Shadow */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.03);
  border-radius: 50%;
  --border-radius: 50%;
  height: 60px;
}

.kgo-btn-browse {
  z-index: 1;
  right: 0;
  bottom: -15px;
  position: absolute;
  --background: #ffffff;
  margin: 0;
  color: #242424;
  border: 4px solid #797979;
  box-sizing: border-box;
  /* Drop Shadow */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.03);
  border-radius: 50%;
  --border-radius: 50%;
  height: 60px;
}

.kgo-btn-browse.kgo-color {
  --background: var(--kgo-color);
  border: 4px solid #ffffff;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));
}

@media screen and (max-width: 480px) {
  .kgo-camera video {
    width: 280px;
  }
}

.kgo-input-light {
  --background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  /* Drop Shadow */

  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
}

.kgo-input-light.form {
  border: 1px solid #c1c1c1;
}

.kgo-bar {
  --background: var(--kgo-color);
  height: 58px;
  color: #ffffff;
}

.kgo-btn-store-cancel {
  --background: #5d5d5d;
  --border-radius: 8px;
}

.kgo-btn-store {
  --background: #242424;
  --border-radius: 8px;
}

.kgo-bar-store {
  --background: #242424;
  height: 58px;
  color: #ffffff;
}

.kgo-bar ion-title,
ion-buttons {
  height: 58px;
  font-size: 16px;
}

.kgo-card {
  --background: #e2e2e2;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin: 15px 0 0 0;
}

.kgo-light {
  --background: #ffffff;
}

.kgo-card ion-card-content {
  padding-top: 9px;
  padding-bottom: 9px;
}

.kgo-card-color,
.kgo-card-img {
  position: relative;
  --background: var(--kgo-color);
}

.kgo-color {
  --background: var(--kgo-color);
}

.kgo-card-img:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url("./kgo-shadow.png") no-repeat center center fixed;
  opacity: 13%;
}

.kgo-row {
  padding-left: 16px;
  padding-right: 16px;
}

.kgo-row ion-col {
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.kgo-card-tran {
  position: relative;
  height: calc(100vh - 370px);
  overflow-y: scroll;
}

.kgo-card-tran-store {
  position: relative;
  height: calc(100vh - 330px);
  overflow-y: scroll;
}

.kgo-card-tran-page {
  position: relative;
  --background: #fd9e18;
  height: calc(100vh - 80px);
  overflow-y: scroll;
}

.kgo-row-tran {
  background: #ffffff;
  border-radius: 6px;
  margin: 12px 0;
}

.kgo-icon-tran {
  font-size: 28px;
  margin-top: 10px;
}

.kgo-text-tran {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}

.kgo-tran-detail {
  padding: 16px 16px 0 16px;
}

.kgo-send-detail {
  padding: 20px 16px;
}

.kgo-m {
  margin-top: 15px;
}

@media screen and (max-height: 700px) {
  .kgo-m {
    margin-top: 0;
  }
}

.kgo-row-bottom {
  margin-top: 20px;
}

@media screen and (max-width: 800px) {
  .kgo-row-bottom {
    position: absolute;
    width: 100%;
    bottom: 77px;
  }
}

/*TODO not work find modal-wrapper*/
.small-modal .modal-wrapper {
  position: absolute;
  bottom: 0;
  min-height: initial;
  top: initial;
  height: 50%;
}

.small-modal .sc-ion-modal-ios-h:first-of-type {
  /*--backdrop-opacity: var(--ion-backdrop-opacity, 0.4);*/
  height: 50%;
}

.kgo-btn-radius {
  --border-radius: 22px;
  font-size: 12px;
  font-weight: 600;
}

.kgo-btn-profile-image {
  z-index: 1;
  right: 135px;
  bottom: 5px;
  position: absolute;
  margin: 0;
  box-sizing: border-box;
  border-radius: 50%;
  --border-radius: 50%;
  height: 30px;

  border: 4px solid #ffffff;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));
}

.ion-text-left {
  margin-right: auto;
}

.mission-text {
  /*margin-top: 16px;*/
  font-size: 16px;
  font-weight: 600;
}

.mission-card-content {
  color: #ffffff;
  height: 95px;
}

.stepper.RFS-StepperContainer {
  padding: 24px 0;
}

.stepper .RFS-StepContainer {
  padding: 0;
}

.label-form {
  /*font-weight: 600;*/
  font-size: 14px;
  line-height: 21px;

  color: #242424;
}

.receive-color {
  background: #f85555;
}

.text-receive-color {
  color: #f85555;
}

.send-color {
  background: #45b865;
}

.text-send-color {
  color: #45b865;
}

.voucher-list {
  height: calc(100vh - 440px);
  overflow-y: scroll;
}

.filterTran {
  text-align: center !important;
}

.filterTran select {
  background: #ffffff;
  color: #0c0c0c;
}

.filterTranText {
  display: none;
  color: #0c0c0c;
}

@media screen and (max-width: 350px) {
  .filterTran {
    text-align: left;
  }
  .filterTran select {
    width: 100%;
  }
  .filterTranText {
    text-align: left;
    display: block;
    margin-left: 5px;
  }
  .filterTranDad {
    display: none;
  }
}

.coin-dollar {
  width: 20px;
  vertical-align: sub;
  margin-left: 7px;
  margin-right: 7px;
}

.ion-checkbox {
  --border-radius: 15px;
}
.btn-end {
  position: absolute;
  right: 0;
  bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 12px;
  float: right;
  border-radius: 24px;
  font-size: 12px;
  color: #ffffff;
  background: #ff8a19;
}

.logoutButton {
  --background: #ec2127;
  --background-activated: #960206;
  --background-focused: #960206;
  --background-hover: #960206;
}
.cover-upload-image {
  width: 100%;
  border: 1px dashed rgba(0, 0, 0, 0.36);
  text-align: center;
  background: #fff;
  border-radius: 4px;
}

.title-input-shop {
  color: #242424;
  font-size: 14px;
  font-weight: 600;
}

.input-shop {
  background: #ffffff;
  border: 1px solid #c1c1c1;
  box-sizing: border-box;
  border-radius: 8px;
}

.bg-editProfile {
  --background: #f5f4f4;
}

@media screen and (max-width: 375px) {
  .small-image-cover {
    width: 100%;
    min-height: 130px;
    background-size: cover;
    object-fit: cover;
    background-position: center;
  }
}

@media screen and (max-width: 750px) {
  .small-image-cover {
    width: 160px;
    height: 160px;
    background-size: cover;
    object-fit: cover;
    background-position: center;
  }
}

@media screen and (min-width: 750px) {
  .small-image-cover {
    width: 116px;
    height: 116px;
    background-size: cover;
    object-fit: cover;
    background-position: center;
  }
}

.app-modal-blackdrop {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.app-modal-box {
  max-width: 360px;
  /*max-height: 240px;*/
  /*height: 240px;*/
  border-radius: 12px;
  background-color: #fefefe;
  margin: 140px auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

.select-type {
  width: 100%;
  border: 0 solid #888;
}
